import React, { useContext } from 'react';
import { 
  Routes, 
  Route, 
  Navigate,
} from "react-router-dom"
import AppLayout from "../layout/AppLayout"
import Dashboard from "../../views/dashboard"
import Login from "../../views/auth/SignIn"
import Meeting from "views/meeting"
import Reports from "views/reports"
import ReportList from "views/reports/ReportList"
import { AuthContext } from '../../shared/context/auth-context';




const Routing = () => {
  const auth = useContext(AuthContext);
  let routes;

  if (auth.isLoggedIn) {
    routes = (
      <Routes>
          <Route path="/" element={<Navigate to={`/dashboard`} replace />} />       
          <Route element={<AppLayout />} >
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='meetings' element={<Meeting />} />
          <Route path='meetings/:keyword' element={<Meeting />} />          
          <Route path='reports' element={<Reports />} />
          <Route path='reports/filter' element={<ReportList />} />
        </Route>
      </Routes>
    );
  } else {
    routes = (
      <Routes>        
        <Route path="/" element={<Login />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }
  return (
    <>
         <main>{routes}</main>
    </>
  )
}

export default Routing