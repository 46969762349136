import { Card } from 'components/ui'
import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../shared/context/auth-context';
import ReportForm from './ReportForm'
import ReportList from './ReportList'
import { 
  Navigate,
} from "react-router-dom"

const Reports = () => {
  const auth = useContext(AuthContext);
  const [submittedFormData, setSubmittedFormData ] = useState(null);

  const handleFormData = (formData) => {        
    setSubmittedFormData(formData);    
  };
  return (
      <>
            {submittedFormData ? (
                <Navigate to={`/reports/filter`} state={submittedFormData} replace />
                
            ) : (
              <ReportForm handleFormData={handleFormData} />
            )}
            
     </>     
  )
}

export default Reports