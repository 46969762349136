import { Input } from "components/ui";
import { useEffect, useState, useContext } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { HiOutlineMenu, HiOutlineMenuAlt2, HiOutlineSearch } from "react-icons/hi";
import NavToggle from "./NavToggle";
import UserDropdown from "./UserDropdown";
import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';

const Header = () => {
   const [active, setActive] = useState(false);
   const [searchInput, setSearchInput] = useState("");
   const navigate = useNavigate();
   const location = useLocation();
   const auth = useContext(AuthContext);
   

   const blankSearchField = () => {
    if(!location.pathname.includes('/meetings/'))
    {
        setSearchInput("");
    }     
  }
  useEffect(() => {    
    blankSearchField();
  }, [ location ]);

   const handleSearch = (value) => {
    setSearchInput(value);
    navigate(`/meetings/${value}`);    
  };

  useEffect(() => {
    if (active) {
      document.body.className = "open";
    } else {
      document.body.className = "none";
    }
  }, [active]);
   // const [isOpen, setOpen] = useState(false);



   // const openDrawer = () => {
	// 	setOpen(true)
	// }

   // console.log(isOpen)

   // const onDrawerClose = e => {
	// 	setOpen(false)
	// }

  return (
    <>
      <header className="header border-b bg-white border-gray-200 dark:border-gray-700">
        <div className="header-wrapper h-16 flex items-center justify-between relative w-full px-4">
        <div className="header-action header-action-start flex items-center">
   <div className="header-action-item header-action-item-hoverable md:hidden ">
      <div className="text-2xl">
      <button onClick={() => setActive(!active)} type="button">
        <span className="is-block">{active ? <HiOutlineMenu /> : <HiOutlineMenuAlt2 /> }</span>
      </button>
      {/* <NavToggle /> */}
      {/* <NavToggle toggled={isOpen} /> */}
         
      </div>
   </div>
   <div className="header-action-item header-action-item-hoverable text-2xl">
   <Input
			className="max-w-md md:w-52" 
			size="sm"
      value={searchInput}
			placeholder="Search..." 
			prefix={<HiOutlineSearch className="text-lg" />} 
			onChange={(e) => handleSearch(e.target.value)} 
		/>
      
   </div>
</div>
            <div className="header-action-item header-action-item-hoverable">
            <UserDropdown />
            </div>
        </div>
      </header>
    </>
  )
}

export default Header