import { Button, Card, Input, FormContainer, FormItem, Spinner, Alert } from 'components/ui';
import React, { useContext, useState } from 'react';

import { Field, Form, Formik } from 'formik'
import { useHttpClient } from '../../../shared/hooks/http-hook';
import { AuthContext } from '../../../shared/context/auth-context';


/*function validateEmail(value) {
    let error
    if (!value) {
        error = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = 'Invalid email address'
    }
    return error
}*/

function validateUsername(value) {    
    if (!value) {
        return 'Required';
    }
    /*if (value === 'admin') {
        return 'Nice try!'
    }*/
    return;
}

function validatePassword(value) {
    if (!value) {
        return 'Required';
    }    
    return;
}

const Login = () => {
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const {loginErrorMessage, setLoginErrorMessage } = useState(null);

  return (
    <>
      <div className="app-layout-blank flex flex-auto flex-col h-[100vh]">
          <div className="h-full flex flex-auto flex-col justify-between">
              <main className='h-full'>
                  <div className="page-container relative h-full flex flex-auto flex-col">
                      <div className="h-full">
                          <div className="container mx-auto flex flex-col flex-auto items-center justify-center min-w-0 h-full">
                              <Card className="min-w-[320px] md:min-w-[450px]" bodyClass="md:p-10">
                                    <div className="text-center">
                                    <div className="mb-4"><h3 className="mb-1">xMeetings</h3></div>
                                    {isLoading && (
                                        <div className="center flex items-center">                
                                            <Spinner size="3.25rem" />
                                        </div>
                                    )} 
                                    {(error) && (
                                        <Alert className="mb-4" type="danger" showIcon>
                                            {error}
                                        </Alert>
                                    )}
                                    {(loginErrorMessage) && (
                                        <Alert className="mb-4" type="danger" showIcon>
                                            {loginErrorMessage}
                                        </Alert>
                                    )}
                                    <Formik
                                        initialValues={{
                                            username: '',
                                            password: '',
                                        }}
                                        onSubmit={ async (values, loginErrorMessage) => {
                                            //console.log(values)
                                            try {
                                                const responseData = await sendRequest(
                                                  process.env.REACT_APP_BACKEND_URL + '/login',
                                                  'POST',
                                                  JSON.stringify({
                                                    username: values.username,
                                                    password: values.password
                                                  }),
                                                  {
                                                    'Content-Type': 'application/json'
                                                  }
                                                );
                                                if(responseData.success)
                                                {
                                                    auth.login(responseData.data.userId, responseData.data.token, responseData.data.name, responseData.data.email, responseData.data.username);
                                                }
                                                else
                                                {
                                                    setLoginErrorMessage('Please enter correct details.')
                                                }
                                              } catch (err) {
                                                
                                              }
                                        }}
                                    >
                                        {({ errors, touched, isValidating }) => (
                                            <Form >
                                                <FormContainer>
                                                    <FormItem
                                                        label="UserName"
                                                        invalid={errors.username && touched.username}
                                                        errorMessage={errors.username}
                                                    >
                                                        <Field
                                                            type="username"
                                                            name="username"
                                                            id="username"
                                                            placeholder="User Name"
                                                            component={Input}
                                                            validate={validateUsername}
                                                        />
                                                    </FormItem>
                                                    <FormItem
                                                        label="Password"
                                                        invalid={errors.password && touched.password}
                                                        errorMessage={errors.password}
                                                    >
                                                        <Field
                                                            type="password"
                                                            name="password"
                                                            id="password"
                                                            placeholder="Password"
                                                            component={Input}
                                                            validate={validatePassword}
                                                        />
                                                    </FormItem>
                                                    <FormItem>
                                                        <Button block type="submit" >
                                                            Submit
                                                        </Button>
                                                    </FormItem>
                                                </FormContainer>
                                            </Form>
                                        )}
                                    </Formik>
                                    
            
                                       
                                    </div>
                              </Card>
                          </div>
                      </div>  
                  </div>
              </main>
          </div>
      </div>
    </>
  )
}

export default Login